
import httpClient from './httpClient';
const prefix = '/core'

export default {
  async login({ username, password }) {
    const { data } = await httpClient({ requiresAuth: false }).post('/login', { username, password })
    
    return  data
  },
  async forgotPassword({ email, username }) {
    const { data } = await httpClient({ requiresAuth: false }).post('/forgot-password', { email, username })

    return  data
  },
  async findEmailByUsername({ username }) {
    const { data } = await httpClient({ requiresAuth: false }).post('/findEmailByUsername', { username })

    return  data
  },
  async verifyOtp({ email, otp }) {
    const { data } = await httpClient({ requiresAuth: false }).post('/verify-otp', { email, otp })
    
    return  data
  },
  async resetPassword({ email, otp, newPassword, username }) {
    const { data } = await httpClient({ requiresAuth: false }).post('/reset-password', { email, otp, newPassword, username })
    
    return  data
  },
  async profile () {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+'/profile')
    
    return  data
  },
  async GetDrivers() {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/drivers`)

    return data
  },
  async get_dashboard(time_start, time_end) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/dashboard?datetime=${time_start}-${time_end}`)

    return data
  },

  async getSumTripVehicle(time_start, time_end) {
  const { data } = await httpClient({ requiresAuth: true }).get(`/getSumTripVehicle?datetime=${time_start}-${time_end}`)

  return data
  },

  async getSumTripDriver(time_start, time_end) {
  const { data } = await httpClient({ requiresAuth: true }).get(`/getSumTripDriver?datetime=${time_start}-${time_end}`)

  return data
  },
  
  async getDriverDashboard(){
    const { data } = await httpClient({ requiresAuth: true }).get(`/getDriverDashboard`)
    return data
  },

  async getVehicleDashboard(){
    const { data } = await httpClient({ requiresAuth: true }).get(`/getVehicleDashboard`)
    return data
  },


  async get_calendar(datetime) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/Booking/GetCalendar`,{
      companyId: 0,
      cusCompanyId: 0,
      dropoffDate: datetime.endOf('month').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      lang: '',
      pickUpDate: datetime.subtract(90, 'days').format('YYYY-MM-DDTHH:mm:ss[Z]')
    })

    return data
  },
  async get_schedule (id, { startDate, endDate }) {
    const { data } = await httpClient({ requiresAuth: true })
      .post(`/Vehicle/GetSchedule`, {
        companyId: 0,
        cusCompanyId: id,
        lang: '',
        startDate, endDate,
        vehicleId: 0
      })

    return data
  },
  async get_companies() {
    const { data } = await httpClient({ requiresAuth: true })
      .post(`/Company/GetTb`, {
        companyName: '',
        companyType: 0,
        orderType: '',
        pageSize: 100,
        pageStart: 1,
        sortField: ''
      })

    return data
  },
  async get_cus_companies() {
    const { data } = await httpClient({ requiresAuth: true })
      .post(`/CusCompany/GetTb`, {
        cName: '',
        code: 0,
        orderType: '',
        pageSize: 100,
        pageStart: 1,
        sortField: '',
        uflName: ''
      })

    return data
  },
  async get_biliing_companies() {
    const { data } = await httpClient({ requiresAuth: true })
      .get(`/CusCompany/GetDdl?canBilling=true`)

    return data
  },
  async get_sales () {
    const { data } = await httpClient({ requiresAuth: true })
      .post(`/Sales/GetTb`, {
        uflName:"",
        nickName:"",
        pageStart:1,
        pageSize:10,
        sortField:"",
        orderType:""
      })

    return data
  },
  async get_gas_station () {
    const { data } = await httpClient({ requiresAuth: true })
      .post(`GasStation/GetTb`, {
        name:null,
        address:null,
        contactName:null,
        pageStart:1,
        pageSize:10,
        sortField:'',
        orderType:''
      })

    return data
  },
  async create_user(role, body) {
      role = capitalizeFirstLetter(role)
      const { data } = await httpClient({ requiresAuth: true }).post(prefix+'/create_user/'+role, body)
      
      return  data
  },

  async update_user(id, role, method, body) {
    role = capitalizeFirstLetter(role)
    const { data } = await httpClient({ requiresAuth: true }).put(prefix+`/update_user/${id}/${role}/${method}`, body)

    return  data
  },

  async get_users(page = 1, perPage = 20) {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/manage_users?page=${page}&perPage=${perPage}`)
      
    return  data
  },

  async get_user_detail(role, params) {
    role = capitalizeFirstLetter(role)
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/user_detail/${role}?${params}`)
      
    return  data
  },

  async remove_user(id, role) {
    role = capitalizeFirstLetter(role)
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/remove_user/${id}/${role}`)

    return  data
  },

  async get_user_profile() {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/user_profile`)

    if (data) localStorage.setItem('user_profile', JSON.stringify(data))
      
    return  data
  },
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}