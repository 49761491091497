<template>
  <div style="height: 100vh; padding: 15px">
    <div class="app-content">
      <a-card title="จัดการใบจองจัดรถ">
        <template #extra>
          <router-link v-if="editable" :to="`/app/quotation/add?edit=true&booking=true`">
            <a-button style="display: flex" type="primary" size="large">
              <template #icon
                ><span class="material-symbols-outlined">Add</span></template
              >
              สร้างใบจองจัดรถ
            </a-button>
          </router-link>
        </template>
        <a-row>
          <a-col :span="8">
            <a-input-search
              v-model:value="search_input"
              placeholder="ค้นหา Booking Number, จุดรับ, จุดส่ง"
              enter-button="ค้นหา"
            />
          </a-col>
          <a-range-picker
            v-model:value="datetime"
            @change="handleTableChange"
            style="margin-left: 1rem"
          />
          <a-button @click="clear_search" type="dashed" style="margin-left: 1rem"
            >CLEAR</a-button
          >
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="show_need_cars" />
            <span style="margin-left: 5px">จัดรถไม่ครบ</span>
          </a-col>
          <a-select
            v-model:value="search_booking_status"
            label-in-value
            style="width: 135px; margin-left: 1rem"
            placeholder="สถานะการจัดรถ"
            @change="handleTableChange"
          >
            <a-select-option :value="0">ครบ</a-select-option>
            <a-select-option :value="1">ไม่ครบ</a-select-option>
            <a-select-option :value="2">เกิน</a-select-option>
          </a-select>
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="require_rollcall" />
            <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
          </a-col>
        </a-row>
        <a-space v-if="editable" style="margin-top: 5px">
          <a-col style="display: flex">
            <a-button
              style="margin-right: 1rem"
              @click="export_job_order()"
              :disabled="!selected_booking"
              type="primary"
            >
              ออกใบงาน(คนขับ)
            </a-button>
            <a-select
              v-if="selected_booking"
              v-model:value="selected_driver_id"
              :disabled="selected_booking.drivers?.length == 0"
              style="width: 200px"
              placeholder="เลือกคนขับ"
            >
              <a-select-option :value="null">ทั้งหมด</a-select-option>
              <a-select-option
                v-for="driver in selected_booking.drivers"
                :value="driver.id"
                :key="driver.id"
              >
                {{ driver?.firstname }} {{ driver?.lastname }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-button @click="show_time_line()" type="primary"> Show Timeline </a-button>
          </a-col>
          <a-col>
            <a-button @click="add_to_billing()" type="primary" style="display: flex">
              <template #icon
                ><span class="material-symbols-outlined">Add</span></template
              >
              เพิ่มเป็น Billing
            </a-button>
          </a-col>
          <a-col>
            <a-button
              @click="show_export_excel=true"
              type="primary"
              style="display: flex;margin-right: 5px"
            >
              <template #icon
                ><span class="material-symbols-outlined">Add</span></template
              >
              ออกรายงานน้ำมัน/ระยะทาง
            </a-button>
          </a-col>
        </a-space>
        <a-space v-if="editable" style="margin-top: 5px">
          <a-col>
            <a-button @click="show_monthly_report = true" type="primary">
              ออกรายงานการจอง
            </a-button>
          </a-col>
          <a-col>
            <a-button @click="export_stops()" type="primary"> ออกรายงานจุดแวะ </a-button>
          </a-col>
          <a-col>
            <a-button @click="export_car_used()" type="primary">
              ออกรายงานการเดินรถ
            </a-button>
          </a-col>
        </a-space>

        <a-modal v-model:visible="show_monthly_report" title="ออกรายงานประจำเดือน">
          <a-form-item style="margin: 0" label="เลือกเดือนที่ต้องการออกรายงาน" />
          <a-range-picker
            v-model:value="monthly_report_date"
            :placeholder="['เริ่มต้น', 'สิ้นสุด']"
            picker="month"
          />
          <template #footer>
            <a-space>
              <a-button key="back" @click="show_monthly_report = false">ปิด</a-button>
              <a-button
                key="submit"
                :loading:value="loading"
                @click="submit_monthly_report()"
                type="primary"
                >ตกลง</a-button
              >
            </a-space>
          </template>
        </a-modal>

        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane
            v-for="tab in filteredTabs"
            :key="tab.status"
          >
            <template #tab>
              <a-badge :count="count_by_status(booking_list, tab.status)">
                <div class="google-icon">
                  <span class="material-symbols-outlined">{{ tab.icon }}</span>
                  <span>{{ tab.title }}</span>
                </div>
              </a-badge>
            </template>
          </a-tab-pane>
        </a-tabs>
        <a-table
          v-if="activeKey != 3 && activeKey != 5 && activeKey != 8 && activeKey != 9 && activeKey != 10 && activeKey != 14"
          rowKey="id"
          :columns="[
            {
              title: 'QTC',
              dataIndex: 'booking_no',
              key: 'booking_no',
              width: 160,
              sorter: {
                compare: (a, b) => b.booking_no - a.booking_no,
                multiple: 1,
              },
            },
            {
              title: 'วันที่ - เวลา รับ',
              dataIndex: 'time_start',
              key: 'time_start',
              width: 150,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_start) - new Date(a.time_start),
                multiple: 2,
              },
            },
            {
              title: 'วันที่ - เวลา ส่ง',
              dataIndex: 'time_end',
              key: 'time_end',
              width: 150,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_end) - new Date(a.time_end),
                multiple: 3,
              },
            },
            {
              title: 'จำนวนวัน',
              dataIndex: 'countDays',
              key: 'countDays',
              width: 100,
              align: 'center',
              sorter: {
                compare: (a, b) => b.countDays - a.countDays,
              },
            },
            {
              title: 'แก้ไขล่าสุด',
              dataIndex: 'updated_at',
              key: 'updated_at',
              width: 200,
              align: 'center',
            },
            {
              title: 'จุดรับ',
              dataIndex: 'start_location_name',
              key: 'start_location_name',
              width: 150,
              align: 'center',
            },
            {
              title: 'จุดส่ง',
              dataIndex: 'end_location_name',
              key: 'end_location_name',
              width: 150,
              align: 'center',
            },
            {
              title: 'ประเภท',
              dataIndex: 'booking_type',
              key: 'booking_type',
              width: 150,
              align: 'center',
            },
            {
              title: 'สถานะ',
              dataIndex: 'status',
              key: 'status',
              width: 120,
              align: 'center',
            },
            {
              title: 'สถานะงาน',
              dataIndex: 'active_job',
              key: 'active_job',
              width: 120,
              align: 'center',
            },
            {
              title: 'สถานะรับงาน',
              dataIndex: 'car_job_status',
              key: 'car_job_status',
              width: 100,
              align: 'center',
            },
            {
              title: 'สถานะการจัดรถ',
              dataIndex: 'booking_cars_status',
              key: 'booking_cars_status',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนรถ',
              dataIndex: 'booking_car_types',
              key: 'booking_car_types',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนรถล่าช้า',
              dataIndex: 'car_late_amount',
              key: 'car_late_amount',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนการออกบิล',
              dataIndex: 'billing_status',
              key: 'billing_status',
              width: 120,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'ลูกค้า/บริษัท',
              dataIndex: 'client_name',
              key: 'client_name',
              width: 316,
            },
            {
              title: 'จำนวนวัน',
              dataIndex: 'total_days',
              key: 'total_days',
              width: 80,
              align: 'center',
            },
            {
              title: 'ผู้โดยสาร',
              dataIndex: 'person_qty',
              key: 'person_qty',
              width: 99,
              align: 'center',
            },
            {
              title: 'รายได้',
              dataIndex: 'net_price',
              key: 'net_price',
              width: 120,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'รายจ่าย',
              dataIndex: 'total_cars_expense',
              key: 'total_cars_expense',
              width: 130,
              align: 'center',
            },
            {
              title: 'กำไรขั้นต้น',
              dataIndex: 'profit',
              key: 'profit',
              width: 130,
              align: 'center',
            },
            {
              title: 'ค่าเบี้ยเลี้ยง',
              dataIndex: 'driverCost',
              key: 'driverCost',
              width: 99,
              align: 'center',
            },
            {
              title: 'สถานะการตรวจสอบ',
              dataIndex: 'finished',
              key: 'finished',
              width: 100,
              align: 'center',
            },
            {
              title: 'ตัวเลือก',
              dataIndex: 'id',
              key: 'id',
              width: 160,
              fixed: 'right',
              align: 'center',
            },
          ]"
          :data-source="filtered_table(booking_list)"
          bordered
          size="small"
          :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
          :row-selection="{
            type: 'radio',
            onSelect: select_row,
          }"
          :pagination="{
            total: pagination.total,
            current: pagination.onpage,
            pageSize: pagination.perPage,
          }"
          @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'countDays' && record[column.key]">
              <a-tag color="green">{{ record[column.key] }}</a-tag>
            </template>
            <template v-if="column.key === 'booking_type' && record[column.key]">
              <span
                ><a-tag :color="tag_option.bookingType[record.booking_type_id]?.color">
                  {{ record.booking_type }}
                </a-tag></span
              >
            </template>
            <template
              v-if="
                (column.key == 'time_start' || column.key == 'time_end') &&
                record[column.key]
              "
            >
              <a-tag color="green">{{ render_time(record[column.key]) }}</a-tag>
            </template>
            <template v-if="column.key === 'status' && record[column.key] != null">
              <span
                ><a-tag :color="tag_option.status[record.status]?.color">
                  {{ tag_option.status[record.status]?.txt }}
                </a-tag></span
              >
            </template>
            <template v-if="column.key == 'profit'">
              {{
                (
                  Number(record.net_price) -
                  Number(sum_car_expense(record.booking_cars))
                ).toLocaleString()
              }}
            </template>
            <template v-if="column.key == 'driverCost'">
              {{ Number(show_driver_cost(record.booking_cars)).toLocaleString() }}
            </template>
            <template v-if="column.key === 'active_job'">
              <a-button
                type="danger"
                @click="activate_job(record)"
                :disabled="record.active_job == 1"
                >{{ record.active_job ? "แจ้งงานแล้ว" : "แจ้งงาน" }}</a-button
              >
            </template>
            <template v-if="column.key === 'finished'">
              <a-button
                type="primary"
                @click="on_booking_finished(record.id)"
                :disabled="record.finished == 1"
                >{{ record.finished ? "ตรวจสอบแล้ว" : "ตรวจสอบ" }}</a-button
              >
            </template>
            <template v-if="column.key === 'total_days'">
              {{ record.total_days + 1 }}
            </template>
            <template v-else-if="column.key === 'net_price' && record[column.key]">
              <b>{{ Number(record.net_price).toLocaleString() }}</b>
            </template>
            <template v-if="column.key === 'billing_status' && record[column.key]">
              <a-tooltip placement="topLeft">
                <template #title>
                  <a-tag
                    v-for="({ billing_type, billing_type_id }, i) in record.billings"
                    :key="i"
                    :color="billing_color[billing_type_id]"
                  >
                    {{ billing_type }}
                  </a-tag>
                </template>
                <a>{{ record.billings?.length }}</a>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'total_cars_expense'">
              <div style="display: flex; align-items: center; justify-content: center">
                <span style="font-size: 16px"
                  >{{ sum_car_expense(record.booking_cars).toLocaleString() }}
                </span>
                <a
                  @click="show_total_cars_expense(record.id)"
                  :style="`height: 16px; color: ${
                    allIsApproved(record.booking_cars) &&
                    sum_car_expense(record.booking_cars)
                      ? 'greenyellow'
                      : 'red'
                  }`"
                >
                  <span
                    style="font-size: 16px; line-height: 0"
                    class="material-symbols-outlined"
                    >pie_chart</span
                  >
                </a>
              </div>
            </template>
            <template v-if="column.key === 'id' && record[column.key]">
              <div>
                <a-tooltip>
                  <template #title>อัพเดทข้อมูล</template>
                  <a @click="show_update_progress(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >update</span
                    ></a
                  >
                </a-tooltip>

                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>แก้ไข</template>
                  <a @click="go_to_edit_quotation(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >drive_file_rename_outline</span
                    ></a
                  >
                </a-tooltip>
                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>ยกเลิก</template>
                  <a @click="remove_booking(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >delete</span
                    ></a
                  >
                </a-tooltip>

                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>คัดลอก {{ record.booking_no }}</template>
                  <a @click="duplicate_booking(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >file_copy</span
                    ></a
                  >
                </a-tooltip>
              </div>
            </template>
            <template v-if="column.key === 'booking_car_types' && record[column.key]">
              <a-tag
                :color="
                  record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                    ? 'green'
                    : 'red'
                "
                >{{ record.booking_cars?.length }} /
                {{ geBookingCarMax(record.booking_car_types) }}</a-tag
              >
            </template>
            <template v-if="column.key === 'car_job_status'">
              <a-tag
                :color="
                  record.booking_cars?.length == record.booking_cars.filter(car => car.status == 8).length
                    ? 'green'
                    : 'red'
                "
                >
              {{ record.booking_cars.filter(car => car.status == 8).length }} / {{ record.booking_cars.filter(car => car.active_car == 1).length}}
              </a-tag>
            </template>
            <template v-if="column.key === 'booking_cars_status'">
              <a-tag
                :color="
               e>   record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                    ? 'green'
                    : 'red'
                "
              >
                {{
                  record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                    ? "ครบ"
                    : record.booking_cars?.length >
                      geBookingCarMax(record.booking_car_types)
                    ? "เกิน"
                    : "ไม่ครบ"
                }}
              </a-tag>
            </template>
            <template v-if="column.key == 'updated_at'">
              {{ record.updated_at }}
              <a-tag
                v-if="compare_time(record.update_at, record.billings)"
                :bordered="false"
                color="error"
                @click="openBookingUpdatePopup(record)"
                >!</a-tag
              >
            </template>
          </template>
        </a-table>

        <a-table
        v-if="activeKey == 3 || activeKey == 5 || activeKey == 8 || activeKey == 9 || activeKey == 10 || activeKey == 14"
        rowKey="id"
          :columns="[
            {
              title: 'QTC',
              dataIndex: 'booking_no',
              key: 'booking_no',
              width: 160,
              sorter: {
                compare: (a, b) => b.booking_no - a.booking_no,
                multiple: 1,
              },
            },
            {
              title: 'Booking car',
              dataIndex: 'booking_car_no',
              key: 'booking_car_no',
              align: 'center',
              width: 150,
            },
            {
              title: 'เบอร์รถ | ทะเบียนรถ',
              dataIndex: 'carNoPlateNo',
              key: 'carNoPlateNo',
              align: 'center',
              width: 180,
            },
            {
              title: 'ชื่อ-นามสกุล ผู้ขับ',
              dataIndex: 'driver_name',
              key: 'driver_name',
              align: 'center',
              width: 180,
            },
            {
              title: 'วันที่ - เวลา รับ',
              dataIndex: 'time_start',
              key: 'time_start',
              width: 150,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_start) - new Date(a.time_start),
                multiple: 2,
              },
            },
            {
              title: 'วันที่ - เวลา ส่ง',
              dataIndex: 'time_end',
              key: 'time_end',
              width: 150,
              align: 'center',
              sorter: {
                compare: (a, b) => new Date(b.time_end) - new Date(a.time_end),
                multiple: 3,
              },
            },
            {
              title: 'จำนวนวัน',
              dataIndex: 'total_days',
              key: 'total_days',
              width: 100,
              align: 'center',
              sorter: {
                compare: (a, b) => b.countDays - a.countDays,
              },
            },
            {
              title: 'แก้ไขล่าสุด',
              dataIndex: 'updated_at',
              key: 'updated_at',
              width: 200,
              align: 'center',
            },
            {
              title: 'จุดรับ',
              dataIndex: 'start_location_name',
              key: 'start_location_name',
              width: 300,
              align: 'center',
            },
            {
              title: 'จุดส่ง',
              dataIndex: 'end_location_name',
              key: 'end_location_name',
              width: 300,
              align: 'center',
            },
            {
              title: 'ประเภท',
              dataIndex: 'booking_type',
              key: 'booking_type',
              width: 150,
              align: 'center',
            },
            {
              title: 'สถานะ',
              dataIndex: 'status',
              key: 'status',
              width: 120,
              align: 'center',
            },
            {
              title: 'สถานะงาน',
              dataIndex: 'active_car',
              key: 'active_car',
              width: 250,
              align: 'center',
            },
            {
              title: 'สถานะรับงาน',
              dataIndex: 'last_booking_status',
              key: 'last_booking_status',
              width: 150,
              align: 'center',
            },
            {
              title: 'สถานะการจัดรถ',
              dataIndex: 'booking_cars_status',
              key: 'booking_cars_status',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนรถ',
              dataIndex: 'booking_car_types',
              key: 'booking_car_types',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนรถล่าช้า',
              dataIndex: 'car_late_amount',
              key: 'car_late_amount',
              width: 80,
              align: 'center',
            },
            {
              title: 'จำนวนการออกบิล',
              dataIndex: 'billing_status',
              key: 'billing_status',
              width: 120,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'ลูกค้า/บริษัท',
              dataIndex: 'client_name',
              key: 'client_name',
              width: 400,
            },
            {
              title: 'ผู้โดยสาร',
              dataIndex: 'person_qty',
              key: 'person_qty',
              width: 99,
              align: 'center',
            },
            {
              title: 'รายได้',
              dataIndex: 'net_price',
              key: 'net_price',
              width: 120,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'รายจ่าย',
              dataIndex: 'total_cars_expense',
              key: 'total_cars_expense',
              width: 130,
              align: 'center',
            },
            {
              title: 'กำไรขั้นต้น',
              dataIndex: 'profit',
              key: 'profit',
              width: 130,
              align: 'center',
            },
            {
              title: 'ค่าเบี้ยเลี้ยง',
              dataIndex: 'driver_cost',
              key: 'driver_cost',
              width: 99,
              align: 'center',
            },
            {
              title: 'สถานะการตรวจสอบ',
              dataIndex: 'finished',
              key: 'finished',
              width: 120,
              align: 'center',
            },
            {
              title: 'ตัวเลือก',
              dataIndex: 'id',
              key: 'id',
              width: 190,
              fixed: 'right',
              align: 'center',
            },
          ]"
          :data-source="filtered_table(booking_list)"
          bordered
          size="small"
          :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
          :row-selection="{
            type: 'radio',
            onSelect: select_row,
          }"
          :pagination="{
            total: pagination.total,
            current: pagination.onpage,
            pageSize: pagination.perPage,
          }"
          @change="handleTableChange"
        >
            <template #bodyCell="{ column, record }">
                  <template v-if="column.key == 'booking_no' && record[column.key]">
                    {{ record.booking_no }}
                  </template>
                  <template v-if="column.key === 'active_car'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px; display: flex; justify-content: center; align-items: center;">
                            <a-tag color="green">
                              {{ booking_cars.active_car ? "แจ้งงานแล้ว" : "แจ้งงาน" }} เมื่อ {{ render_time(booking_cars.active_car_timestamp) }}
                            </a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'booking_car_no'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <p>{{ booking_cars.booking_car_no }}</p>
                        </div>
                      </div>
                    </div>
                  </template>       
                  <template v-if="column.key == 'carNoPlateNo'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <p>{{ getCarNoByCarId(booking_cars.car_id) }} | {{ getPlateNoByCarId(booking_cars.car_id) }}</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'driver_name'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <p>{{ getFirstNameByDrierId(booking_cars.driver_id) }} {{ getLastNameByDrierId(booking_cars.driver_id) }}</p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="(column.key == 'time_start') &&record[column.key]">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag color="green">{{ render_time(booking_cars.time_start) }}</a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="(column.key == 'time_end') &&record[column.key]">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag color="green">{{ render_time(booking_cars.time_end) }}</a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'total_days'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag color="green">{{ record[column.key] }}</a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'updated_at'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <p>{{ booking_cars.updated_at }} </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'start_location_name'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <div v-for="stop in firstStartStop(booking_cars.stops)" :key="stop.id">
                            <div v-if="stop.stop_type_id == 1">
                              <p>{{ stop.name }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key == 'end_location_name'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 1px; margin-top: 16px;">
                          <div v-for="stop in endStartStop(booking_cars.stops)" :key="stop.id">
                            <div v-if="stop.stop_type_id == 6">
                              <p>{{ stop.name }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'booking_type' && record[column.key]">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag :color="tag_option.bookingType[record.booking_type_id]?.color">
                            {{ record.booking_type }}
                          </a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'status' && record[column.key] != null">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px 10px;">
                          <span
                            ><a-tag :color="tag_option.status[record.status]?.color">
                              {{ tag_option.status[record.status]?.txt }}
                            </a-tag>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template> 
                  <template v-if="column.key === 'booking_cars_status'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px 10px;">
                          <a-tag
                            :color="
                          e>   record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                                ? 'green'
                                : 'red'
                            "
                          >
                            {{
                              record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                                ? "ครบ"
                                : record.booking_cars?.length >
                                  geBookingCarMax(record.booking_car_types)
                                ? "เกิน"
                                : "ไม่ครบ"
                            }}
                          </a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'last_booking_status'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag
                            v-if="
                              booking_cars.logs?.filter(
                                ({ model_name }) => model_name == 'BookingCarStatus'
                              ).length
                            "
                            color="green"
                          >
                            {{
                              render_date(
                                booking_cars.logs.filter(
                                  ({ model_name }) => model_name == "BookingCarStatus"
                                )[0].created_at,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            }}
                          </a-tag>
                          <a-tag color="red" v-else> ยังไม่ได้รับงาน </a-tag>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'booking_car_types' && record[column.key]">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tag
                            :color="
                              record.booking_cars?.length == geBookingCarMax(record.booking_car_types)
                                ? 'green'
                                : 'red'
                            "
                            >{{ record.booking_cars?.length }} /
                            {{ geBookingCarMax(record.booking_car_types) }}</a-tag
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'car_late_amount'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                            {{ record.car_late_amount }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'billing_status' && record[column.key]">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <a-tooltip placement="topLeft">
                            <template #title>
                              <a-tag
                                v-for="({ billing_type, billing_type_id }, i) in record.billings"
                                :key="i"
                                :color="billing_color[billing_type_id]"
                              >
                                {{ billing_type }}
                              </a-tag>
                            </template>
                            <a>{{ record.billings?.length }}</a>
                          </a-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'client_name'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                            {{ record.client_name }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'person_qty'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                            {{ record.person_qty }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'finished'">
                  <div style="display: flex; justify-content: center; align-items: center;">
                    <a-button
                      type="primary"
                      @click="on_booking_finished(record.id)"
                      :disabled="record.finished == 1"
                      >{{ record.finished ? "ตรวจสอบแล้ว" : "ตรวจสอบ" }}</a-button>
                  </div>
                  </template>
                  <template v-else-if="column.key === 'net_price' && record[column.key]">
                    <b>{{ Number(record.net_price).toLocaleString() }}</b>
                  </template>
                  <template v-if="column.key === 'total_cars_expense'">
                    <div style="display: flex; align-items: center; justify-content: center">
                      <span style="font-size: 16px"
                        >{{ sum_car_expense(record.booking_cars).toLocaleString() }}
                      </span>
                      <a
                        @click="show_total_cars_expense(record.id)"
                        :style="`height: 16px; color: ${
                          allIsApproved(record.booking_cars) &&
                          sum_car_expense(record.booking_cars)
                            ? 'greenyellow'
                            : 'red'
                        }`"
                      >
                        <span
                          style="font-size: 16px; line-height: 0"
                          class="material-symbols-outlined"
                          >pie_chart</span
                        >
                      </a>
                    </div>
                  </template>
                  <template v-if="column.key == 'profit'">
                    {{
                      (
                        Number(record.net_price) -
                        Number(sum_car_expense(record.booking_cars))
                      ).toLocaleString()
                    }}
                  </template>
                  <template v-if="column.key == 'driver_cost'">
                    <!-- {{ Number(show_driver_cost(record.booking_cars)).toLocaleString() }} -->
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                            {{ Number(booking_cars.driver_cost).toLocaleString() }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.key === 'id'">
                    <div v-for="booking_cars in record.booking_cars" :key="booking_cars.id">
                      <div v-if="booking_cars.active_car == 1">
                        <div style="padding: 15px;">
                          <template v-if="column.key === 'id' && record[column.key]">
                            <div>
                              <a-tooltip>
                                <template #title>อัพเดทข้อมูล</template>
                                <a @click="show_update_progress(record.id)"
                                  ><span style="font-size: 14px" class="material-symbols-outlined"
                                    >update</span
                                  ></a
                                >
                              </a-tooltip>

                              <a-divider type="vertical"></a-divider>
                              <a-tooltip>
                                <template #title>แก้ไข</template>
                                <a @click="go_to_edit_quotation(record.id)"
                                  ><span style="font-size: 14px" class="material-symbols-outlined"
                                    >drive_file_rename_outline</span
                                  ></a
                                >
                              </a-tooltip>
                              <a-divider type="vertical"></a-divider>
                              <a-tooltip>
                                <template #title>ยกเลิก</template>
                                <a @click="remove_booking(record.id)"
                                  ><span style="font-size: 18px" class="material-symbols-outlined"
                                    >delete</span
                                  ></a
                                >
                              </a-tooltip>

                              <a-divider type="vertical"></a-divider>
                              <a-tooltip>
                                <template #title>คัดลอก {{ record.booking_no }}</template>
                                <a @click="duplicate_booking(record.id)"
                                  ><span style="font-size: 18px" class="material-symbols-outlined"
                                    >file_copy</span
                                  ></a
                                >
                              </a-tooltip>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </a-card>
          </div>

    <a-modal
      v-if="selected_booking"
      v-model:visible="show_timeline"
      :title="`รายละเอียด Booking ID :${selected_booking.booking_no}`"
    >
      <a-timeline direction="vertical" :current="1">
        <a-timeline-item
          v-for="{
            stop_type_id,
            stop_type,
            expected_timestamp,
            name,
            remark,
          } in selected_booking.stops"
        >
          <p>
            <span
              ><a-tag :color="tag_option.status[stop_type_id]?.color"
                >{{ stop_type }}
              </a-tag></span
            >
            {{ render_time(expected_timestamp) }} {{ name }} <br />
            โดย {{ selected_booking.sale.username }}<br />
            หมายเหตุ {{ remark ? remark : "-" }}
          </p>
        </a-timeline-item>
      </a-timeline>
      <template #footer>
        <a-space>
          <a-button key="back" @click="show_timeline = false">ปิด</a-button>
        </a-space>
      </template>
    </a-modal>

    <a-modal
      v-model:visible="update_progress_modal"
      :title="`Booking ID :${selected_booking?.booking_no}`"
      @ok="update_progress"
    >
      <a-card>
        <a-form :model="selected_booking" layout="vertical">
          <a-form-item label="สถานะ">
            <a-select v-model:value="selected_booking.status" placeholder="เลือกสถานะ">
              <a-select-option
                v-for="({ txt }, i) in tag_option.status"
                :key="i"
                :value="i"
                selected
              >
                {{ txt }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
    </a-modal>

    <a-modal
      v-model:visible="total_cars_expense_modal"
      :title="`ต้นทุนค่าใช้จ่าย ใบจองจัดรถ ID :${selected_booking?.booking_no}`"
      width="80%"
      @ok="save_car_expenses"
      @cancel="handleCloseCarExpenses"
      :footer="null"
    >
    <a-select 
      v-model="search_plate_no" 
      style="width: 170px; margin-bottom: 10px;" 
      placeholder="ทะเบียนรถ"
      @change="handlePlateNoChange">
      <a-select-option 
        v-for="{ car } in selected_booking.booking_cars" 
        :key="car?.plate_no"
        :value="car?.plate_no"
      >
        ทะเบียนรถ: {{ car?.plate_no }}
      </a-select-option>
    </a-select>
      <div v-if="total_car_expenses">

        <a-collapse
          v-model:activeKey="total_car_expenses_collapse"
          expand-icon-position="right"
          style="background-color: white; font-size: 18px; font-weight: 500"
        >
          <a-collapse-panel key="1">
            <template #header>
              <div>
                1. ค่าทางด่วน - 
                {{ total_car_expenses.toll_ways?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.toll_ways?.length }}
                <a-tag v-if="showWarningTollWays" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totaTollWays }}บาท
            </template>
            
            <a-button
              @click="init_toll_way()"
              type="primary"
              style="margin-bottom: 10px"
              >เพิ่มค่าทางด่วน</a-button
            >
            <a-table
              :dataSource="filteredTollWays"
              :pagination="false"
              :columns="[
                {
                  title: 'ทะเบียนรถ',
                  dataIndex: 'plate_no',
                  key: 'plate_no',
                },
                {
                  title: 'ผู้ชำระ',
                  dataIndex: 'driver_paid',
                  key: 'driver_paid',
                  align: 'center',
                  width: 100,
                },
                {
                  title: 'จำนวนเงิน',
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'วัน-เวลา',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  align: 'center',
                  width: 180,
                },
                {
                  title: 'หมายเหตุกรณีไม่อนุมัติ',
                  dataIndex: 'approve_remark',
                  key: 'approve_remark',
                },
                {
                  title: 'สถานะ',
                  dataIndex: 'granted',
                  key: 'granted',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                  width: 100,
                },
              ]"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'driver_paid'">
                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                </template>
                <template v-if="column.key === 'created_at'">
                  {{ render_date(record.created_at) }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select
                    v-model:value="record.granted"
                    @change="
                      update_granted(
                        record,
                        'toll_ways',
                        $event,
                        total_car_expenses.toll_ways
                      )
                    "
                    style="width: 120px"
                    placeholder="เลือกสถานะ"
                  >
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option
                      v-for="{ id, name } in expense_logs"
                      :key="id"
                      :value="id"
                    >
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'toll_ways')"
                        ><span style="font-size: 14px" class="material-symbols-outlined"
                          >drive_file_rename_outline</span
                        ></a
                      >
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'toll_ways')"
                        ><span style="font-size: 18px" class="material-symbols-outlined"
                          >delete</span
                        ></a
                      >
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="2">
            <template #header>
              <div>
                2. ค่าน้ำมัน - 
                {{ total_car_expenses.gas_refills?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.gas_refills?.length }}
                <a-tag v-if="showWarningGasRefills" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totalGasRefills }}บาท
            </template>

            <a-button
              @click="show_gas_refill_drawer = true; bill_photo_show_source = ''"
              type="primary"
              style="margin-bottom: 10px"
              >เพิ่มค่าน้ำมัน</a-button
            >
            <a-table
              :dataSource="filteredGasRefills"
              :pagination="false"
              :columns="[
                {
                  title: 'ทะเบียนรถ',
                  dataIndex: 'plate_no',
                  key: 'plate_no',
                },
                {
                  title: 'การชำระเงิน',
                  dataIndex: 'pay_method_id',
                  key: 'pay_method_id',
                  align: 'center',
                  width: 120,
                },
                {
                  title: 'จำนวนเงิน',
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'วัน-เวลา',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  align: 'center',
                  width: 180,
                },
                {
                  title: 'หมายเหตุกรณีไม่อนุมัติ',
                  dataIndex: 'approve_remark',
                  key: 'approve_remark',
                },
                {
                  title: 'สถานะ',
                  dataIndex: 'granted',
                  key: 'granted',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                  width: 100,
                },
              ]"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'pay_method_id'">
                  {{ pay_method_list.find((e) => record.pay_method_id == e.id)?.name }}
                </template>
                <template v-if="column.key === 'created_at'">
                  {{ render_date(record.created_at) }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select
                    v-model:value="record.granted"
                    @change="
                      update_granted(
                        record,
                        'gas_refills',
                        $event,
                        total_car_expenses.gas_refills
                      )
                    "
                    style="width: 120px"
                    placeholder="เลือกสถานะ"
                  >
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option
                      v-for="{ id, name } in expense_logs"
                      :key="id"
                      :value="id"
                    >
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'gas_refills')"
                        ><span style="font-size: 14px" class="material-symbols-outlined"
                          >drive_file_rename_outline</span
                        ></a
                      >
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'gas_refills')"
                        ><span style="font-size: 18px" class="material-symbols-outlined"
                          >delete</span
                        ></a
                      >
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="3">
            <template #header>
              <div>
                3. ค่าใช้จ่ายอื่นๆ - 
                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                {{ total_car_expenses.expenses?.length }}
                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
              </div>
            </template>
            <template #extra>
              {{ totalExpense }}บาท
            </template>
            <a-button
              @click="show_create_expense = true"
              type="primary"
              style="margin-bottom: 10px"
              >เพิ่มค่าใช้จ่ายเพิ่มเติม</a-button
            >
            <a-table
              :dataSource="filteredExpense"
              :pagination="false"
              :columns="[
                {
                  title: 'ทะเบียนรถ',
                  dataIndex: 'plate_no',
                  key: 'plate_no',
                },
                {
                  title: 'ผู้ชำระ',
                  dataIndex: 'driver_paid',
                  key: 'driver_paid',
                  align: 'center',
                  width: 100,
                },
                {
                  title: 'จำนวนเงิน',
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'รายการ',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                },
                {
                  title: 'หมายเหตุกรณีไม่อนุมัติ',
                  dataIndex: 'approve_remark',
                  key: 'approve_remark',
                },
                {
                  title: 'สถานะ',
                  dataIndex: 'granted',
                  key: 'granted',
                  align: 'center',
                  width: 150,
                },
                {
                  title: 'Action',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                  width: 100,
                },
              ]"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'driver_paid'">
                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                </template>
                <template v-if="column.key === 'granted'">
                  <a-select
                    v-model:value="record.granted"
                    @change="
                      update_granted(
                        record,
                        'expenses',
                        $event,
                        total_car_expenses.expenses
                      )
                    "
                    style="width: 120px"
                    placeholder="เลือกสถานะ"
                  >
                    <a-select-option :value="null">รออนุมัต</a-select-option>
                    <a-select-option
                      v-for="{ id, name } in expense_logs"
                      :key="id"
                      :value="id"
                    >
                      {{ name }}
                    </a-select-option>
                  </a-select>
                </template>
                <template v-if="column.key === 'id' && record[column.key]">
                  <div>
                    <a-tooltip>
                      <template #title>แก้ไข</template>
                      <a @click="edit_expense_log(record, 'expenses')"
                        ><span style="font-size: 14px" class="material-symbols-outlined"
                          >drive_file_rename_outline</span
                        ></a
                      >
                    </a-tooltip>
                    <a-divider type="vertical"></a-divider>
                    <a-tooltip>
                      <template #title>ยกเลิก</template>
                      <a @click="remove_expense_log(record.id, 'expenses')"
                        ><span style="font-size: 18px" class="material-symbols-outlined"
                          >delete</span
                        ></a
                      >
                    </a-tooltip>
                  </div>
                </template>
              </template>
            </a-table>
          </a-collapse-panel>

          <a-collapse-panel key="4" :header="`4. ค่าเช่ารถ`">
            <template #extra>
              {{ totalRentalCost }} บาท</template>
              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                ทะเบียนรถ: {{ car?.plate_no }} :
                {{ (Number(rental_price)).toLocaleString() }} บาท<br />
              </span>
          </a-collapse-panel>

          <a-collapse-panel key="5" :header="`5. ค่าเบี้ยเลี้ยง`">
            <template #extra>
              {{ totalDriverCost }} บาท</template>
              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                ทะเบียนรถ: {{ car?.plate_no }} :
                {{ (Number(driver_cost)).toLocaleString() }} บาท<br />
              </span>
          </a-collapse-panel>
        </a-collapse>

        <a-card>
          <div style="display: flex; justify-content: space-between">
            <h4>รวมต้นทุนค่าเช่ารถ</h4>
            <a-space>
              <!-- <a-button type="primary" :disabled="selected_booking.paid_expense" @click="on_expense_paided">{{ selected_booking.paid_expense?'จ่ายแล้ว':'จ่าย'}}</a-button> -->
              <h4 style="margin: auto">
                {{ sum_expense(selected_booking.booking_cars).toLocaleString() }} บาท
              </h4>
            </a-space>
          </div>
        </a-card>

        <a-drawer
          v-model:visible="show_gas_refill_drawer"
          placement="right"
          title="บันทึกค่าน้ำมัน"
          @close="create_gas_refill = {}"
        >
          <a-form
            name="create_gas_refill"
            autocomplete="off"
            layout="vertical"
            :model="create_gas_refill"
            @finish="submit_create_gas_refill"
          >
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="ทะเบียนรถ"
                  name="booking_car_id"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]"
                >
                  <a-select
                    v-model:value="create_gas_refill.booking_car_id"
                    placeholder="เลือกรถ"
                  >
                    <a-select-option
                      v-for="{ id, car: { plate_no } } in selected_booking.booking_cars"
                      :key="id"
                      :value="id"
                    >
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-switch
              v-model:checked="create_gas_refill.use_gas_station"
              checkedChildren="ปั้มที่ร่วมกับบริษัท"
              unCheckedChildren="ปั้มนอก"
            />
            <a-form-item
              v-if="create_gas_refill.use_gas_station"
              label="เลือกปั้มน้ำมัน"
              name="gas_station_id"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
            >
              <a-select
                v-model:value="create_gas_refill.gas_station_id"
                placeholder="เลือกปั้มน้ำมัน"
              >
                <a-select-option
                  v-for="{ id, name } in gas_station_list"
                  :key="id"
                  :value="id"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-else
              label="ระบุชื่อปั้ม"
              name="station_name"
              :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
            >
              <a-textarea v-model:value="create_gas_refill.station_name" />
            </a-form-item>

            <a-form-item
              label="ชนิดน้ำมัน"
              name="gas_type"
              :rules="[
                { required: true, message: 'เลือกชนิดน้ำมัน' },
              ]"
            >
              <a-select
                v-model:value="create_gas_refill.gas_type"
                placeholder="เลือกชนิดน้ำมัน"
              >
                <a-select-option :value="'Diesel'"
                  >Diesel</a-select-option
                >
                <a-select-option :value="'Diesel B7'"
                  >Diesel B7</a-select-option
                >
                <a-select-option :value="'Vepower Diesel'"
                  >Vepower Diesel</a-select-option
                >
                <a-select-option :value="'EV'"
                  >EV</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="จำนวนลิตร"
                  name="refill_amount"
                  :rules="[{ required: true, message: 'กรุณากรอกจำนวนลิตร' }]"
                >
                  <a-input-number
                    v-model:value="create_gas_refill.refill_amount"
                    :min="0"
                    :max="1000"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="จำนวนเงิน"
                  name="amount"
                  :rules="[{ required: true, message: 'กรุณากรอกยอดจ่าย' }]"
                >
                  <a-input-number
                    v-model:value="create_gas_refill.amount"
                    :min="0"
                    :max="100000"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item
              label="ประเภทการจ่ายเงิน"
              name="pay_method_id"
              :rules="[{ required: true, message: 'เลือกประเภทการจ่ายเงิน' }]"
            >
              <a-select
                v-model:value="create_gas_refill.pay_method_id"
                placeholder="เลือกประเภทการจ่ายเงิน"
              >
                <a-select-option
                  v-for="{ id, name } in pay_method_list"
                  :key="id"
                  :value="id"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="วันเวลา"
              name="datetime"
              :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
            >
              <a-date-picker
                v-model:value="create_gas_refill.datetime"
                :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm"
                placeholder="เลือกเวลา"
                :minuteStep="1"
              />
            </a-form-item>

            <a-form-item name="bill_photo" label="รูปหลักฐานการโอน">
              <a-upload
                v-model:file-list="gas.bill_photo_show"
                list-type="picture-card"
                class="avatar-uploader"
                accept="image/*"
                :show-upload-list="false"
                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                @change="upload_files($event, 'bill_photo_show','gas_refills')"
                method="GET"
                @remove="remove_uploaded_file"
              >
              <div v-if="bill_photo_show_source!=''" 
              style=" position: relative;
                      width: 100%;
                      height: 100%;">
                <img
                  :src="bill_photo_show_source"
                  class="uploaded-image"
                  alt="uploaded image"
                  style=" width: 100%;
                          object-fit: cover;
                          transition: filter 0.3s ease;
                          cursor: pointer;"
                />
              </div>
              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                <p class="ant-upload-drag-icon">
                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                </p>
                คลิกเพื่ออัพโหลดรูปภาพ
              </div>
              </a-upload>
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer
          v-model:visible="show_create_toll_way"
          placement="right"
          title="เพิ่มรายการค่าทางด่วน"
        >
          <a-form
            :model="create_toll_way"
            layout="vertical"
            @finish="submit_create_toll_way"
          >
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="ทะเบียนรถ"
                  name="booking_car_id"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]"
                >
                  <a-select
                    v-model:value="create_toll_way.booking_car_id"
                    placeholder="เลือกรถ"
                  >
                    <a-select-option
                      v-for="{ id, car: { plate_no } } in selected_booking.booking_cars"
                      :key="id"
                      :value="id"
                    >
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item
              label="ผู้ชำระ"
              name="driver_paid"
              :rules="[{ required: true, message: 'กรุณากรอกผู้ชำระ' }]"
            >
              <a-radio-group v-model:value="create_toll_way.driver_paid">
                <a-radio-button value="1">คนขับ</a-radio-button>
                <a-radio-button value="0">บริษัท</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              label="สถานะงาน"
              name="booking_status"
              :rules="[{ required: true, message: 'เลือกสถานะงาน' }]"
            >
              <a-select
                v-model:value="create_toll_way.booking_status"
                placeholder="เลือกสถานะงาน"
              >
                <a-select-option :value="8">ก่อนเริ่มงาน</a-select-option>
                <a-select-option :value="7">ระหว่างงาน</a-select-option>
                <a-select-option :value="11">หลังจบงาน</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="จำนวนเงิน"
              name="amount"
              :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
            >
              <a-input-number
                v-model:value="create_toll_way.amount"
                :min="0"
                :max="1000000"
              />
            </a-form-item>

            <a-form-item
              label="วันเวลา"
              name="datetime"
              :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
            >
              <a-date-picker
                v-model:value="create_toll_way.datetime"
                :show-time="{ format: 'HH:mm' }"
                format="DD/MM/YYYY HH:mm"
                placeholder="เลือกเวลา"
                :minuteStep="1"
              />
            </a-form-item>


            <a-form-item label="ใบรูปหลักฐานการโอน" name="photo_1">
              <div class="upload-section">
                <a-upload
                  v-model:file-list="toll_way_photo"
                  list-type="picture-card"
                  class="avatar-uploader"
                  accept="image/*"
                  :show-upload-list="false"
                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                  @change="upload_files($event, 'photo_1_show' , 'toll_ways', create_toll_way.index)"
                  method="GET"
                >
                  <img
                    v-if="create_toll_way.photo_1"
                    :src="create_toll_way.photo_1"
                    :style="`width: 100%;object-fit: cover`"
                    alt="avatar"
                  />
                  <div v-else>
                    <p class="ant-upload-drag-icon">
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 5rem"
                      >upload_file</span>
                    </p>
                    คลิกเพื่ออัปโหลดรูปภาพ
                  </div>
                </a-upload>
              </div>
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>

        <a-drawer
          v-model:visible="show_create_expense"
          placement="right"
          title="เพิ่มค่าใช้จ่ายเพิ่มเติม"
        >
          <a-form
            :model="create_expense"
            layout="vertical"
            @finish="submit_create_expense"
          >
            <a-row justify="space-between">
              <a-col :span="11">
                <a-form-item
                  label="ทะเบียนรถ"
                  name="booking_car_id"
                  :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]"
                >
                  <a-select
                    v-model:value="create_expense.booking_car_id"
                    placeholder="เลือกรถ"
                  >
                    <a-select-option
                      v-for="{ id, car: { plate_no } } in selected_booking.booking_cars"
                      :key="id"
                      :value="id"
                    >
                      {{ plate_no }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="11">
                <a-form-item
                  label="ผู้ชำระ"
                  name="driver_paid"
                  :rules="[{ required: true, message: 'กรุณาเลือกผู้ชำระ' }]"
                >
                  <a-radio-group v-model:value="create_expense.driver_paid">
                    <a-radio-button value="1">คนขับ</a-radio-button>
                    <a-radio-button value="0">บริษัท</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item
              label="รายการ"
              name="expense_id"
              :rules="[{ required: true, message: 'กรุณากรอกรายการ' }]"
            >
              <a-select v-model:value="create_expense.expense_id">
                <a-select-option
                  v-for="expense in expense_list"
                  :key="expense.id"
                  :value="expense.id"
                >
                  {{ expense.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-row justify="space-between">
              <a-col :span="24">
                <a-form-item
                  label="จำนวนเงิน"
                  name="amount"
                  :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
                >
                  <a-input-number v-model:value="create_expense.amount" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item label="หมายเหตุ" name="remark">
              <a-textarea v-model:value="create_expense.remark" />
            </a-form-item>

            <a-row justify="end">
              <a-button
                html-type="submit"
                type="primary"
                size="large"
                style="margin: 4px; text-align: right"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 16px; margin-right: 4px"
                  >Edit</span
                >
                บันทึกข้อมูล
              </a-button>
            </a-row>
          </a-form>
        </a-drawer>
      </div>
    </a-modal>

    <JobOrder
      v-if="job_order"
      :booking_detail="export_data"
      :user_name="user_name"
      :selected_driver_id="selected_driver_id"
      @close="job_order = false"
    />
    <BookingUpdatedPopup
      :isVisible="BookingUpdatedVisible"
      :booking_updated="bookingUpdatedData"
      @close="closeBookingUpdatePopup"/>
  </div>
  <a-modal v-model:visible="show_export_excel" title="รายงานน้ำมัน/ระยะทาง" :footer="null">
        <a-form :model="export_excel" layout="vertical" @finish="exportFile()">
          <a-row justify="center">
            <a-col :span="20">
              <a-form-item
                      label="ทะเบียนรถ"
                      name="plate_no"
                      :rules="[{ required: true, message: 'โปรดเลือกทะเบียนรถ' }]"
                    >
                    <a-select
                      v-model:value="export_excel.plate_no"
                      placeholder="เลือกทะเบียนรถ"
                      style="width: 300px"
                      show-search
                    >
                      <a-select-option v-for="{plate_no} in car_list" :value="plate_no">{{plate_no}}</a-select-option>
                    </a-select>
                  </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="center">
            <a-col :span="20">
              <a-form-item label="วันที่เริ่ม" :rules="[{ required: true, message: 'โปรดระบุวันที่เริ่ม' }]" :name="time_start">
                  <a-date-picker style="width: 300px;" v-model:value="export_excel.time_start" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="center">
            <a-col :span="20">
              <a-form-item label="วันที่สิ้นสุด" :rules="[{ required: true, message: 'โปรดระบุวันที่สิ้นสุด' }]" name="time_end">
                  <a-date-picker style="width: 300px;" v-model:value="export_excel.time_end" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="end">
              <a-button type="primary" html-type="submit">บันทึก</a-button>
          </a-row>
        </a-form>
    </a-modal>
</template>

<script>
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import BookingService from "../../api/BookingService";
import { exportXLSXFile } from "@/components/helpers.js";
import ReportService from "../../api/ReportService";
import Swal from "sweetalert2";
import JobOrder from "@/components/modals/job_order.vue";
import Utility from "../../utility";
import SystemService from "../../api/SystemService";
import { message } from "ant-design-vue";
import th from "dayjs/locale/th";
import BookingUpdatedPopup from "@/components/modals/booking_update_popup.vue";
import * as xlsx from "xlsx-js-style";

export default {
  components: { JobOrder ,BookingUpdatedPopup},
  props: {
    user_name: String,
  },
  name: "Booking",
  data() {
    return {
      model_form: {
          key: null // Initialize with your appropriate key
      },
      //BookingUpdatedPopup
      booking_sjinda: false,
      BookingUpdatedVisible: false,
      bookingUpdatedData: null,
      bookingUpdated: {
        booking_no: '', 
        total_cars_expense: null, 
      },
      //TM department
      tmDepartment: null,
      tabs: [
        { icon: 'menu', title: 'ทั้งหมด', status: null, activate_job: 1, roles: ['adminTM', 'preDelivery','planner','rollCall','others',] },
        { icon: 'list', title: 'แนะนำการจัดรถ', status: 0, roles: ['preDelivery','planner','others',] },
        { icon: 'list', title: 'รอจัดรถ', status: 1, roles: ['planner','others',] },
        { icon: 'list', title: 'จัดรถแล้ว', status: 2, roles: ['planner','others',] },
        { icon: 'list', title: 'แจ้งงาน', status: 14, roles: ['planner','others',] },
        { icon: 'list', title: 'รับงาน', status: 8, roles: ['planner','rollCall','others',] },
        { icon: 'list', title: 'ถึงจุดรับรถแล้ว', status: 3, roles: ['planner','others',] },
        { icon: 'list', title: 'เริ่มงาน', status: 9, roles: ['planner','rollCall','others',] },
        { icon: 'list', title: 'รับลูกค้าขึ้นยานพาหนะ', status: 10, roles: ['planner','rollCall','others',] },
        { icon: 'list', title: 'จบงาน', status: 5, roles: ['adminTM','planner','rollCall','others',] },
        { icon: 'list', title: 'พร้อมเริ่มงาน', status: 11, roles: ['rollCall','others',] },
        { icon: 'list', title: 'รับลูกค้าแล้วเสร็จ', status: 4, roles: ['rollCall','others'] },
        { icon: 'list', title: 'ตรวจสอบแล้ว', status: 100, roles: ['adminTM','others',] },
        { icon: 'list', title: 'ยกเลิกการเดินทาง', status: 6, roles: ['others',] },
        { icon: 'list', title: 'กำลังเดินทาง', status: 7, roles: ['others',] },
        { icon: 'list', title: 'รับของ', status: 12, roles: [,'others'],},
        { icon: 'list', title: 'รับของเสร็จสิ้น', status: 13, roles: ['others',] },
      ],
      showWarningTollWays: false,
      showWarningGasRefills: false,
      showWarningExpenses: false,

      editTollWay: null,
      newTollWay: false,
      newGas: false,
      newExpense: false,

      selected_booking: {
        booking_cars: [
          {
            plate_no: null,
          },
        ],
      },

      editable: Utility.get_permission_editable_with_key("booking"),
      booking_list: [],
      pagination: {
        total: 0,
        onpage: 1,
        perPage: 20,
      },
      ///filter
      search_input: null,
      datetime: null,
      search_booking_status: null,
      search_plate_no: null,

      activeKey: null,
      show_need_cars: false,
      search_booking_status: null,
      require_rollcall: false,
      selected_driver_id: null,
      tag_option: {
        bookingType: {
          1: { txt: "เที่ยวเดียว (ขาเดียว)", color: "pink" },
          2: { txt: "ไป-กลับ", color: "red" },
          3: { txt: "รายวัน (เต็มวัน)", color: "orange" },
          4: { txt: "รายวัน (ครึ่งวัน)", color: "green" },
          5: { txt: "เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)", color: "cyan" },
          6: { txt: "เฉพาะวัน (ระบุวัน)", color: "blue" },
          7: { txt: "ใช้รถทุกวัน", color: "purple" },
          8: { txt: "รายเดือน", color: "green" },
        },
        status: [
          { txt: "แนะนำการจัดรถ", color: "processing" },
          { txt: "รอจัดรถ", color: "processing" },
          { txt: "จัดรถแล้ว", color: "warning" },
          { txt: "ถึงจุดรับรถแล้ว", color: "warning" },
          { txt: "รับลูกค้าแล้วเสร็จ", color: "success" },
          { txt: "จบงาน", color: "success" }, 
          { txt: "ยกเลิกการเดินทาง", color: "error" }, 
          { txt: "กำลังเดินทาง", color: "warning" }, 
          { txt: "รับงาน", color: "success" }, 
          { txt: "เริ่มงาน", color: "success" }, 
          { txt: "รับลูกค้าขึ้นยานพาหนะ", color: "success" }, 
          { txt: "พร้อมเริ่มงาน", color: "success" }, 
          { txt: "รับของ", color: "success" }, 
          { txt: "รับของเสร็จสิ้น", color: "success" }, 
          { txt: "แจ้งงาน", color: "warning" },
        ],
      },
      gas: {
        bill_photo_show: [],
        indexGas:null,
        orderPos:null,
      },
      bill_photo_show_source:"",
      toll_way_photo:[],
      billing_color: {
        1: "green",
        2: "red",
        3: "purple",
        4: "yellow",
      },
      selected_booking: null,
      export_data: null,
      show_monthly_report: false,
      monthly_report_date: null,
      loading: false,
      show_timeline: false,
      job_order: false,

      /* update_progress_modal */
      update_progress_modal: false,

      /* total_cars_expense_modal */
      total_cars_expense_modal: false,

      pay_method_list: [],
      expense_log: {
        toll_ways: null,
        gas_refills: null,
        extras: null,
      },
      expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
      show_gas_refill_drawer: false,
      create_gas_refill: {
        bill_photo:[]
      },
      gas_station_list: [],

      show_create_toll_way: false,
      create_toll_way: {},

      show_create_expense: false,
      create_expense: {},
      expense_list: [],
      car_list: [],

      total_car_expenses_collapse: null,
      show_export_excel: false,
      export_excel: {
        time_start: null,
        time_end: null,
        plate_no: null,
      }
    };
  },
  computed: {
    // shouldShowTable() {
    //   const activeTab = this.filteredTabs.find(tab => `${tab.status}-${tab.active_car}` == this.activeKey);
    //   return activeTab && activeTab.active_car;
    // },
    filteredTabs() {

      this.tmDepartment = JSON.parse(localStorage.getItem("user_profile")).department;
      if(this.tmDepartment != null){return this.tabs.filter(tab => tab.roles.includes(this.tmDepartment));}
      else{return this.tabs.filter(tab => tab.roles.includes("others"));}
    },
    filteredTollWays() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.toll_ways) {
        return []; 
      }
      return this.total_car_expenses.toll_ways.filter(item => item.plate_no === selectedPlateNo);
    },
    totaTollWays() {
    return this.filteredTollWays
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },
    
    filteredGasRefills() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.gas_refills) {
        return []; 
      }
      return this.total_car_expenses.gas_refills.filter(item => item.plate_no === selectedPlateNo);
    },
    totalGasRefills() {
    return this.filteredGasRefills
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },

    filteredExpense() {
      const selectedPlateNo = this.search_plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.expenses) {
        return []; 
      }
      let result = this.total_car_expenses.expenses.filter(item => item.plate_no === selectedPlateNo)
      result.forEach((item) => {
        item.name = this.expense_list.find(expense => expense.id === item.expense_id).name
      })
      return result
    },
    totalExpense() {
    return this.filteredExpense
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },

    filteredBookingCars() {
    const selectedPlateNo = this.search_plate_no;
    if (!selectedPlateNo) {
      return [];
    }
    return this.selected_booking.booking_cars.filter(({ car }) => car.plate_no === selectedPlateNo);
  },
  totalRentalCost() {
    return this.filteredBookingCars
      .map(({ rental_price }) => {
        const rentalPriceNum = parseFloat(rental_price);
        if (!isNaN(rentalPriceNum)) {
          return rentalPriceNum;
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
  },

  totalDriverCost() {
    return this.filteredBookingCars
      .map(({ driver_cost}) => {
        const driverCostNum = parseFloat(driver_cost);
        if (!isNaN(driverCostNum)) {
          return driverCostNum;
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
  },

    total_car_expenses() {
      const cars = this.selected_booking.booking_cars;
      const tollways_list = [];
      const gas_refills_list = [];
      const expenses_list = [];

      cars.forEach(({ toll_ways, gas_refills, expenses, car }) => {
        toll_ways.forEach((tollway) => {
          tollway.plate_no = car.plate_no;
          tollways_list.push(tollway);
        });

        gas_refills.forEach((gas_refill) => {
          gas_refill.plate_no = car.plate_no;
          gas_refills_list.push(gas_refill);
        });

        expenses.forEach((expense) => {
          const {
            expense: { name },
            booking_car_id,
            amount,
            id,
            granted,
            driver_paid,
            expense_id,
            approve_remark,
            edit_expenses_status,
          } = expense;
          expenses_list.push({
            id,
            booking_car_id,
            amount,
            plate_no: car.plate_no,
            granted,
            approve_remark,
            driver_paid,
            expense_id,
            edit_expenses_status,
          });
        });
      });

      return {
        toll_ways: tollways_list,
        gas_refills: gas_refills_list,
        expenses: expenses_list,
      };
    },
  },
  methods: {
    async upload_files(e, key, target, id) {
    if (target && !id) {
      await this.getBase64(e.file.originFileObj, key, target);
    } else if (id) {
      await this.getBase64(e.file.originFileObj, key, target, id);
    } else {
      await this.getBase64(e.file.originFileObj, key);
    }

    console.log(`After upload_files:`, this.service);

    return `https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda`;
  },

  async getBase64(file, key, target, id) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64String = reader.result;

        if (target) {
          target[key] = base64String;
        } else if (id) {
          await SystemService.update_all(id.model, { data: { [key]: base64String } }, id.id);
        } else {
          this.service[key] = base64String;
        }
      };
    } catch (err) {
      console.error("เกิดข้อผิดพลาดในการแปลงไฟล์เป็น Base64:", err);
    }
  },
  async getBase64(file, key) {
    console.log('key', key);
    
      if (key) {
          const vue = this
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
              console.log("TestTo",reader.result)
              vue.model_form[key] = reader.result
              vue.model_form[`${key}_file_name`] = file.name
          };
      } else {
          return await new Promise((resolve, reject) => {
              if (file) {
                  if (typeof file == 'string') {
                      resolve(file)
                  } else {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = error => reject(error);
                  }
              }else{
                reject('error')
              }
          });
      }
  },
  async getBase64Image(e, file) {
      e.stopPropagation();
      
      if(file.url){
        const result = await this.getBase64ImageFromURL(file.url);
  
        // download
        let a = document.createElement('a');
        a.href = result;
        a.download = 'image.png';
        a.click();
      }else{
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          const base64String = reader.result;
          let a = document.createElement('a');
          a.href = base64String;
          a.download = 'image.png';
          a.click();
        };
      }
  },
  async remove_uploaded_file({ file, index, uid }) {
    if (uid) {
      const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
      if (fileIndex !== -1) {
        this.gas.photo_2_show.splice(fileIndex, 1);
      }
    } else if (index !== undefined) {
      this.gas.bill_photo_show.splice(index, 1);
    } else {
      const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
      if (fileIndex !== -1) {
        this.gas.photo_2_show.splice(fileIndex, 1);
      }
    }
  },
  handleClickDeletePhoto(e , key , model_name , index = null , sub_index = null) {
      console.log("index" , index);
      console.log("sub_index" , sub_index);      
      console.log("this.gas", this.gas);
      
      
      e.stopPropagation(); // ป้องกันการกระทำอื่นๆ ที่เกิดขึ้น
      console.log(e , key , model_name);
      
      if(model_name == 'gas_refills'){
       if (key == 'bill_photo_show') {          
          this.gas.bill_photo_show = [];
        }
      }
    },
  upload_files(e, key , model_name = null , index = null , sub_index = null) {
    if (e.file.status != 'remove') {
        console.log("index : ",index,"subInd",sub_index)
        this.getBase64(e.file.originFileObj, key)
        if (model_name == 'gas_refills') {
          if (key == 'bill_photo_show') {
            console.log("checkShow",this.gas.bill_photo_show)
              //this.create_booking.booking_cars[index].gas_refills[sub_index].bill_photo = this.model_form.bill_photo_show
              this.bill_photo_show_source = this.model_form.bill_photo_show
          }
        } else if (model_name == 'toll_ways') {
            if (key == 'photo_1_show') {
              // this.assign_car_form.toll_ways[index].photo_1 = this.model_form.photo_1_show
              this.create_toll_way.photo_1 = this.model_form.photo_1_show
            }
        }
        return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
    }
  },
  showPreview(e , fileUrl) {
    e.stopPropagation();
    this.previewImage = fileUrl;
    this.previewVisible = true;
  },
  getUploadedUrl(file) {
    // ตรวจสอบว่าการอัปโหลดสำเร็จและมี URL หรือไม่
    if(!Array.isArray(file)){
      return file
    }
    if (file.response && file.response.url) {
        return file.response.url;
    }
    return file.url || URL.createObjectURL(file.originFileObj);
  },
  handlePreview(file) {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
    // window.open(this.previewImage);
  },
  handleIconClick(action, payload, event) {
      event.stopPropagation();
      if (action === 'preview') {
          this.showPreview(event , payload);
      } else if (action === 'delete') {
          // this.model_form[payload] = '';
          this.fileList = [];
      }
  },
    firstStartStop(stops) {
      if (!stops || stops.length === 0) return [];
      const filteredStops = stops.filter(stop => stop.stop_type_id == 1);
      filteredStops.sort((a, b) => new Date(a.expected_timestamp) - new Date(b.expected_timestamp));
      return filteredStops.length > 0 ? [filteredStops[0]] : [];
    },
    endStartStop(stops) {
      if (!stops || stops.length === 0) return [];
      const filteredStops = stops.filter(stop => stop.stop_type_id == 6);
      filteredStops.sort((a, b) => new Date(a.expected_timestamp) - new Date(b.expected_timestamp));
      return filteredStops.length > 0 ? [filteredStops[0]] : [];
    },
    getCarNoByCarId(car_id) {
      const record = this.filtered_table(this.booking_list);
      let carNo = 'Car not found';
      record.forEach((item) => {
        item.cars.forEach(car => {
          if (car.id === car_id) {
            carNo = car.car_no;
          }
        });
      });
      return carNo;
    },
    getPlateNoByCarId(car_id) {
      const record = this.filtered_table(this.booking_list);
      let plateNo = 'Car not found';
      record.forEach((item) => {
        item.cars.forEach(car => {
          if (car.id === car_id) {
            plateNo = car.plate_no;
          }
        });
      });
      return plateNo;
    },
    getFirstNameByDrierId(driver_id) {
      const record = this.filtered_table(this.booking_list);
      let firstname = 'firstname not found';
      record.forEach((item) => {
        item.drivers.forEach(driver => {
          if (driver.id === driver_id) {
            firstname = driver.firstname;
          }
        });
      });
      return firstname;
    },
    getLastNameByDrierId(driver_id) {
      const record = this.filtered_table(this.booking_list);
      let lastname = 'lastname not found';
      record.forEach((item) => {
        item.drivers.forEach(driver => {
          if (driver.id === driver_id) {
            lastname = driver.lastname;
          }
        });
      });
      return lastname;
    },
    handledateTimeChange() {
      console.log("handledateTimeChange");
        this.filtered_table(this.booking_list);
    },
    // bookingupdate method
    openBookingUpdatePopup(record) {
      this.BookingUpdatedVisible = true;
      this.bookingUpdatedData = record.booking_updated;
    },
    closeBookingUpdatePopup() {
      this.BookingUpdatedVisible = false;
    },
    async exportFile() {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });

      const time_start = dayjs(this.export_excel.time_start).format("YYYY-MM-DD");
      const time_end = dayjs(this.export_excel.time_end).format("YYYY-MM-DD");
      const plate_no = this.export_excel.plate_no;
      const { data } = await ReportService.booking_gas_report(plate_no, time_start, time_end);

      const headersColumn = [
        { header: "Booking No.", key: "booking_no" },
        { header: "หมายเลข", key: "car_no" },
        { header: "ทะเบียนรถ (คนขับ)", key: "plate_no" },
        { header: "ยี่ห้อ", key: "car_brand" },
        { header: "วัน - เวลา เริ่มงาน", key: "time_start" },
        { header: "วัน - เวลา จบงาน", key: "time_end" },
        { header: "สถานที่เติม", key: "gas_station" },
        { header: "น้ำมัน", key: "fuel" },
        { header: "จำนวนจุดแวะ", key: "stops" },
        { header: "Bht/L", key: "bht_per_litre" },
        { header: "ราคา", key: "total_price" },
        { header: "เลขไมล์", key: "odos_distance_end" },
        { header: "ระยะทาง", key: "odos_distance" },
        { header: "ลิตร", key: "litre" },
        { header: "Km/l", key: "km_per_litre" },
        { header: "B/km", key: "bht_per_km" },
      ];

      const workBook = xlsx.utils.book_new();
      const workSheet = xlsx.utils.json_to_sheet(data, { header: headersColumn.map(h => h.key) });
      const lastRow = data.length + 1;

      xlsx.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

      headersColumn.forEach((header, index) => {
        let cell = workSheet[xlsx.utils.encode_cell({ c: index, r: 0 })];
        cell.s = {
          font: { bold: true },
          fill: { fgColor: { rgb: "ADC8E9" } },
          border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
          alignment: { horizontal: "center" }
        };
      });

      for (let row = 1; row < lastRow; row++) {
        for (let col = 0; col < headersColumn.length; col++) {
          const cellAddress = xlsx.utils.encode_cell({ c: col, r: row });
          let cell = workSheet[cellAddress];
          if (!cell) {
            cell = { v: "" };
            workSheet[cellAddress] = cell;
          }
          cell.s = {
            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
            alignment: { horizontal: "center" }
          };
        }

        const jCellAddress = xlsx.utils.encode_cell({ c: 9, r: row });
        const kCellAddress = xlsx.utils.encode_cell({ c: 10, r: row });
        const kValue = workSheet[kCellAddress].v;
        const mCellAddress = xlsx.utils.encode_cell({ c: 12, r: row });
        const mValue = workSheet[mCellAddress].v;
        const nCellAddress = xlsx.utils.encode_cell({ c: 13, r: row });
        const nValue = workSheet[nCellAddress].v;
        const oCellAddress = xlsx.utils.encode_cell({ c: 14, r: row });
        const pCellAddress = xlsx.utils.encode_cell({ c: 15, r: row });

        let calBhtPerLitre = '';
        if (kValue > nValue && kValue > 0 && nValue > 0) {
          calBhtPerLitre = `=${kCellAddress}/${nCellAddress}`;
        } else if (kValue < nValue && kValue > 0 && nValue > 0) {
          calBhtPerLitre = `=${nCellAddress}/${kCellAddress}`;
        } else if (kValue == 0 && nValue == 0) {
          calBhtPerLitre = `0`;
        } else if (kValue == 0 && nValue > 0) {
          calBhtPerLitre = `0`;
        } else if (kValue > 0 && nValue == 0) {
          calBhtPerLitre = `0`;
        }
        workSheet[jCellAddress] = {
          s: {
            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
            alignment: { horizontal: "center" },
            numFmt: "0.00"
          },
          f: calBhtPerLitre
        };

        let calKmPerLitre = '';
        if (mValue > nValue && mValue > 0 && nValue > 0) {
          calKmPerLitre = `=${mCellAddress}/${nCellAddress}`;
        } else if (mValue < nValue && mValue > 0 && nValue > 0) {
          calKmPerLitre = `=${nCellAddress}/${mCellAddress}`;
        } else if (mValue == 0 && nValue == 0) {
          calKmPerLitre = `0`;
        } else if (mValue == 0 && nValue > 0) {
          calKmPerLitre = `0`;
        } else if (mValue > 0 && nValue == 0) {
          calKmPerLitre = `0`;
        }
        workSheet[oCellAddress] = {
          s: {
            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
            alignment: { horizontal: "center" },
            numFmt: "0.00"
          },
          f: calKmPerLitre
        };

        let calBhtPerKm = '';
        if (kValue > mValue && kValue > 0 && mValue > 0) {
          calBhtPerKm = `=${kCellAddress}/${mCellAddress}`;
        } else if (kValue < mValue && kValue > 0 && mValue > 0) {
          calBhtPerKm = `=${mCellAddress}/${kCellAddress}`;
        } else if (kValue == 0 && mValue == 0) {
          calBhtPerKm = `0`;
        } else if (kValue == 0 && mValue > 0) {
          calBhtPerKm = `0`;
        } else if (kValue > 0 && mValue == 0) {
          calBhtPerKm = `0`;
        }
        workSheet[pCellAddress] = {
          s: {
            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
            alignment: { horizontal: "center" },
            numFmt: "0.00"
          },
          f: calBhtPerKm
        };
      }

      workSheet['!cols'] = headersColumn.map(() => ({ wch: 20 }));

      workSheet[`J${lastRow + 2}`] = {
        v: "รวมดีเซลวีเพาเวอร์",
        s: {
          font: { bold: true },
          fill: { fgColor: { rgb: "FFFF00" } }
        }
      };

      const sumFormula = `SUM(K2:K${lastRow})`;
      workSheet[`K${lastRow + 2}`] = { f: sumFormula, s: { numFmt: "0.00" } };

      const sumFormulaDistance = `SUM(M2:M${lastRow})`;
      workSheet[`M${lastRow + 2}`] = { f: sumFormulaDistance, s: { numFmt: "0.00" } };

      const sumFormulaLitre = `SUM(N2:N${lastRow})`;
      workSheet[`N${lastRow + 2}`] = { f: sumFormulaLitre, s: { numFmt: "0.00" } };

      const sumFormulaKmPerLitre = `M${lastRow + 2}/N${lastRow + 2}`;
      workSheet[`O${lastRow + 2}`] = { f: sumFormulaKmPerLitre, s: { numFmt: "0.00" } };

      const sumFormulaBhtPerKm = `K${lastRow + 2}/M${lastRow + 2}`;
      workSheet[`P${lastRow + 2}`] = { f: sumFormulaBhtPerKm, s: { numFmt: "0.00" } };

      if (!workSheet['!ref']) {
        workSheet['!ref'] = xlsx.utils.encode_range({
          s: { r: 0, c: 0 },
          e: { r: lastRow + 1, c: headersColumn.length - 1 }
        });
      } else {
        const range = xlsx.utils.decode_range(workSheet['!ref']);
        range.e.r = lastRow + 1;
        workSheet['!ref'] = xlsx.utils.encode_range(range);
      }

      xlsx.utils.book_append_sheet(workBook, workSheet, "Report");
      xlsx.writeFile(workBook, "รายงานน้ำมัน/ระยะทาง.xlsx");
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    handleCloseCarExpenses() {
      this.total_cars_expense_modal = false;
      this.showWarningExpenses = false;
      this.showWarningGasRefills = false;
      this.showWarningTollWays = false;
    },
    sum_expense() {
      const plateNo = this.search_plate_no;
      if (!plateNo) {
        return 0;
      }
      const bookingCars = this.filteredBookingCars.filter(({ car }) => car.plate_no === plateNo);
      return bookingCars
        .map(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
          const tollWaysSum = Array.isArray(toll_ways)
            ? toll_ways.reduce((a, b) => a + (b.granted === 1 ? (Number(b.amount) || 0) :0), 0)
            : 0;
          const gasRefillsSum = Array.isArray(gas_refills)
            ? gas_refills.reduce((a, b) => a + (b.granted === 1 ? (Number(b.amount) || 0) :0), 0)
            : 0;
          const expensesSum = Array.isArray(expenses)
            ? expenses.reduce((a, b) => a + (b.granted === 1 ? (Number(b.amount) || 0) :0), 0)
            : 0;
          const driverCost = Number(driver_cost) || 0;
          const rentalPrice = Number(rental_price) || 0;
          return tollWaysSum + gasRefillsSum + expensesSum + driverCost + rentalPrice;
        })
        .reduce((a, b) => a + b, 0);
    },
    handlePlateNoChange(value) {
      this.search_plate_no = value;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.onpage = pagination.current;
      this.pagination.perPage = pagination.pageSize;

      this.init_bookings();
    },
    compare_time(time, array) {
      const lastupdate = array.map(({ created_at }) => created_at);
      //if time is greater than any of lastupdate and no any of lastupdate is greater than time
      return array
        ? lastupdate.some((lastupdate) => dayjs(time).isAfter(lastupdate))
        : false;
    },
    geBookingCarMax(car_types) {
      return car_types
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0);
    },
    getCarStatusMax(car_status_job) {
      return car_status_job
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0);
    },
    async refresh_total_cars_expense_modal() {
      message.loading({
        content: "กำลังโหลดข้อมูลใหม่...",
        key: "refresh_total_cars_expense_modal",
      });
      // this.total_cars_expense_modal = false
      await this.init_bookings();
      this.selected_booking = this.booking_list.find(
        (booking) => booking.id === this.selected_booking.id
      );
      // this.total_cars_expense_modal = true
      message.success({
        content: "โหลดข้อมูลเรียบร้อยแล้ว",
        key: "refresh_total_cars_expense_modal",
      });
    },
    async update_granted({ id, approve_remark }, type, value, list) {
      try {
        const result = {
          approve_remark,
          granted: value,
        };
        if (value == 2) {
          await Swal.fire({
            title: "กรุณาระบุเหตุผลที่ไม่อนุมัติ",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return value;
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(({ isConfirmed, value }) => {
            // console.log({ isConfirmed, value, type });
            if (value && isConfirmed) {
              if (result.approve_remark?.includes("ไม่อนุมัติ")) {
                result.approve_remark = result.approve_remark.split(" (ไม่อนุมัติ")[0];
                result.approve_remark = `${result.approve_remark} (ไม่อนุมัติ: ${value})`;
              } else {
                result.approve_remark = `${
                  result.approve_remark ? result.approve_remark : ""
                } (ไม่อนุมัติ: ${value})`;
              }

              list.forEach((item) => {
                if (item.id === id) {
                  item.approve_remark = result.approve_remark;
                }
              });
            } else {
              list.forEach((item) => {
                if (item.id === id) {
                  item.granted = null;
                }
              });
              throw "ยกเลิกการอัพเดท";
            }
          });
        }

        switch (type) {
          case "toll_ways":
            SystemService.update_all("TollWay", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "gas_refills":
            SystemService.update_all("GasRefill", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "expenses":
            SystemService.update_all("BookingCarExpense", { data: result }, id).then(
              () => {
                this.refresh_total_cars_expense_modal();
                message.success("อัพเดทข้อมูลสำเร็จ");
              }
            );
            break;
        }
            let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
            this.bookingUpdated["total_cars_expense"] = currentTime
            BookingService.booking_update_timestamp(this.bookingUpdated)
      } catch (err) {
        console.log(err);
      }
    },
    submit_create_expense() {
      const e = this.create_expense;
      const { id } = e;
      delete e.datetime;
      delete e.plate_no;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      
      if (id) {
        SystemService.update_all("BookingCarExpense", { data: e }, id).then(() => {
          SystemService.update_all("BookingCarExpense", { data: {edit_expenses_status: 1} }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_create_expense = false;
            this.create_expense = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_expenses_status == 1 ){
              this.newExpense = true;
            }
            this.showWarningExpenses = true;
          });
        });
      } else {
        SystemService.create_all("BookingCarExpense", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_create_expense = false;

          this.create_expense = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      this.total_cars_expense_modal = false;
      setTimeout(() => {
        this.total_cars_expense_modal = true;
      }, 1000);
    },
    submit_create_toll_way() {
      const e = this.create_toll_way;
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      delete e.datetime;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)

      if (id) {
        SystemService.update_all("TollWay", { data: e }, id).then(() => {
          SystemService.update_all("TollWay", { data: {edit_toll_ways_status: 1} }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_create_toll_way = false;
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
              if (e.edit_toll_ways_status == 1 ){
              this.newTollWay = true;
              }
            this.showWarningTollWays = true;
          });
        });
      } else {
        SystemService.create_all("TollWay", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_create_toll_way = false;
          this.create_toll_way = {};
          this.model_form.photo_1_show = null
          this.refresh_total_cars_expense_modal();
        });
      }
      this.total_cars_expense_modal = false;
      setTimeout(() => {
        this.total_cars_expense_modal = true;
      }, 1000);
    },
    submit_create_gas_refill() {
      this.create_gas_refill.bill_photo = this.model_form.bill_photo_show
      const e = this.create_gas_refill;
      console.log("check",e);
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      delete e.datetime;
      delete e.use_gas_station;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      if (id) {
        SystemService.update_all("GasRefill", { data: e }, id).then(() => {
          SystemService.update_all("GasRefill", { data: {edit_gas_status: 1} }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            this.show_gas_refill_drawer = false;
            this.create_gas_refill = {};
            this.refresh_total_cars_expense_modal();
            if (e.edit_gas_status == 1 ){
              this.newGas = true;
            }
            this.showWarningGasRefills = true;
          });
        });
      } else {
        SystemService.create_all("GasRefill", { data: [e] }).then(() => {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.show_gas_refill_drawer = false;
          this.create_gas_refill = {};
          this.refresh_total_cars_expense_modal();
        });
      }
      this.total_cars_expense_modal = false;
      setTimeout(() => {
        this.total_cars_expense_modal = true;
      }, 1000);
    },
    edit_expense_log(data, type) {
      switch (type) {
        case "toll_ways":
          this.show_create_toll_way = true;
          this.create_toll_way = data;
          this.create_toll_way.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
        case "gas_refills":
          console.log("order",data)
          //this.gas.bill_photo_show = data.bill_photo
          for (const [key, value] of Object.entries(data)) {
            if(key == 'bill_photo' && value != null){
              this.bill_photo_show_source = value
            }else if(key == 'bill_photo' && value == null){
              this.bill_photo_show_source = ''
            }
          }
          // this.gas.indexGas = data.index
          // this.gas.orderPos = this.create_booking.booking_cars.find(oc=>oc.id == obj.booking_car_id).order_pos-1
          this.show_gas_refill_drawer = true;
          this.create_gas_refill = data;
          this.create_gas_refill.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          this.create_gas_refill.use_gas_station = data.gas_station_id != null;
          break;
        case "expenses":
          this.show_create_expense = true;
          this.create_expense = data;
          this.create_expense.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
      }
    },
    remove_expense_log(model_id, type) {
      const old_active_key = this.total_car_expenses_collapse;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      switch (type) {
        case "toll_ways":
          SystemService.delete_all("TollWay", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.toll_ways.splice(
              this.total_car_expenses.toll_ways.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.toll_ways = car.toll_ways?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "gas_refills":
          SystemService.delete_all("GasRefill", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.gas_refills.splice(
              this.total_car_expenses.gas_refills.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.gas_refills = car.gas_refills?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "expenses":
          SystemService.delete_all("BookingCarExpense", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.expenses.splice(
              this.total_car_expenses.expenses.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.booking_cars.forEach((car) => {
              car.expenses = car.expenses?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
      }
      this.refresh_total_cars_expense_modal();
    },
    async on_expense_paided() {
      SystemService.update_all(
        "Booking",
        { data: { paid_expense: true } },
        this.selected_booking.id
      ).then((res) => {
        this.total_cars_expense_modal = false;
        this.init_bookings();
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    render_date(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },
    sum_car_expense(booking_cars) {
      return booking_cars
        .map(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
          const tollWaysSum = Array.isArray(toll_ways)
            ? toll_ways.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const gasRefillsSum = Array.isArray(gas_refills)
            ? gas_refills.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const expensesSum = Array.isArray(expenses)
            ? expenses.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const driverCost = Number(driver_cost) || 0;
          const rentalPrice = Number(rental_price) || 0;
          return tollWaysSum + gasRefillsSum + expensesSum + driverCost + rentalPrice;
        })
        .reduce((a, b) => a + b, 0);
    },
    show_driver_cost(booking_cars) {
      return booking_cars
        .map(({ driver_cost}) => {
          const driverCost = Number(driver_cost) || 0;
          return driverCost
        })
        .reduce((a, b) => a + b, 0).toFixed(0); 
    },
    allIsApproved(booking_cars) {
      return booking_cars.every(({ toll_ways, gas_refills, expenses }) => {
        return (
          toll_ways.every(({ granted }) => granted == 1) &&
          gas_refills.every(({ granted }) => granted == 1) &&
          expenses.every(({ granted }) => granted == 1)
        );
      });
    },
    show_billings(bills_array) {
      return bills_array.map(({ billing_type }) => billing_type).join(", ");
    },
    async duplicate_booking(id) {
      message.loading({ content: "กำลังโหลด...", key: "loading" });
      const data = await BookingService.duplicate_booking(id);
      await this.init_bookings();
      message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    async remove_booking(id) {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบงานนี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SystemService.update_all("Booking", { data: { progress: 0 } }, id);
          this.init_bookings();
          Swal.fire("ลบเรียบร้อย", "ลบงานเรียบร้อย", "success");
        }
      });
    },
    save_car_expenses() {},
    show_total_cars_expense(id) {
      this.total_cars_expense_modal = true;
      this.selected_booking = this.booking_list.find((booking) => booking.id === id);
      this.bookingUpdated["booking_no"] = this.selected_booking.booking_no;
      this.selected_booking.booking_cars.forEach((car) => {
        car.toll_ways.map((tollway) => {
          if (tollway.edit_toll_ways_status == 1) {
            this.showWarningTollWays = true;
          }
        });
        car.gas_refills.map((gas) => {
          if (gas.edit_gas_status == 1) {
            this.showWarningGasRefills = true;
          }
        });
        car.expenses.map((expense) => {
          if (expense.edit_expenses_status == 1) {
            this.showWarningExpenses = true;
          }
        });
      });
    },
    activate_job(record) {  
      const { id, booking_cars } = record;
      console.log('id', id)
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      SystemService.update_all("Booking", { data: { active_job: 1 } }, id).then((res) => {
        this.init_bookings();
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        booking_cars.map(({ id }) => {
          SystemService.update_all("BookingCar", { data: { active_car: 1 } }, id).then((res) => {
            this.init_bookings();
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
          });
        });
        })
    },
    on_booking_finished(id) {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      SystemService.update_all("Booking", { data: { finished: true } }, id).then(
        (res) => {
          this.init_bookings();
          this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        }
      );
    },
    show_update_progress(id) {
      this.update_progress_modal = true;
      this.selected_booking = this.booking_list.find((booking) => booking.id === id);
    },
    async update_progress() {
      SystemService.update_all(
        "Booking",
        { data: { status: this.selected_booking.status } },
        this.selected_booking.id
      ).then((res) => {
        this.update_progress_modal = false;
        this.init_bookings();
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    add_to_billing() {
      SystemService.update_all(
        "Booking",
        { data: { billing_status: 1 } },
        this.selected_booking.id
      );
      Swal.fire("เพิ่มเรียบร้อย", "เพิ่มงานเรียบร้อย", "success");
    },
    show_time_line() {
      if (this.selected_booking) {
        this.show_timeline = true;
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกงาน",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async export_booking_gas_report() {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });

      console.log("this.export_excel", this.export_excel);
      const time_start = dayjs(this.export_excel.time_start).format("YYYY-MM-DD");
      const time_end = dayjs(this.export_excel.time_end).format("YYYY-MM-DD");
      console.log("time_start", time_start);
      console.log("time_end", time_end);
      const { headers, data } = await ReportService.booking_gas_report(time_start, time_end);
      exportXLSXFile(headers, data, `Booking-Gas-Report-${time_start}-${time_end}`);

      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    export_job_order() {
      if (this.selected_booking) {
        this.export_data = JSON.parse(JSON.stringify(this.selected_booking));
        if (this.selected_driver_id) {
          this.export_data.booking_cars = this.selected_booking.booking_cars?.filter(
            ({ driver_id }) => driver_id === this.selected_driver_id
          );
          setTimeout(() => {
            this.job_order = true;
          }, 100);
        } else {
          setTimeout(() => {
            this.job_order = true;
          }, 100);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกงาน",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async export_car_used() {
      const { headers, data } = await ReportService.car_used();
      exportXLSXFile(headers, data, `CarUsed-Report`);
    },
    async export_stops() {
      const { headers, data } = await ReportService.stops();
      exportXLSXFile(headers, data, `Trip-Report`);
    },
    submit_monthly_report() {
      this.show_monthly_report = false;
      const obj = [
        {
          date: "2021-01-01",
          incoming: 10,
          confirmed: 5,
          confirmed_percentage: 50,
          cancel: 5,
          cancel_percentage: 50,
          turndown: 0,
          turndown_percentage: 0,
          urgent: 0,
          urgent_percentage: 0,
          advance: 0,
          advance_percentage: 0,
          total: 10,
        },
      ];

      var headers = {
        date: "Month",
        incoming: "Incoming",
        confirmed: "Confirmed",
        confirmed_percentage: "Confirm (%)",
        cancel: "Cancel",
        cancel_percentage: "Cancel (%)",
        turndown: "Turn Down",
        turndown_percentage: "TurnDown (%)",
        urgent: "Urgent",
        urgent_percentage: "Urgent (%)",
        advance: "Advance",
        advance_percentage: "Advance (&)",
        total: "Total",
      };

      var fileTitle = `Monthly Report`;

      exportXLSXFile(headers, obj, fileTitle);
    },
    count_by_status(array, status) {
      return status != null
        ? array?.filter(({ status: booking_status, finished }) => {
            ///finished check
            if (status == 100) return finished;
            else {
              return booking_status == status && !finished;
            }
          })?.length
        : array?.length;
    },
    clear_search() {
      this.search_input = null;
      this.datetime = null;
      this.search_booking_status = null;
      this.init_bookings();
    },
    select_row(selectedRowKeys) {
      this.selected_booking = selectedRowKeys;
    },
    select_filter(key, status) {
      console.log({ key, status });
    },
    filtered_table(array) {
      return array
        .filter(({ booking_cars, booking_car_types }) => {
          if (this.search_booking_status == null) return true;
          if (this.search_booking_status.value == 0) {
            var result = booking_cars.length == this.geBookingCarMax(booking_car_types);
            return result;
          } else if (this.search_booking_status.value == 1) {
            var result = booking_cars.length < this.geBookingCarMax(booking_car_types);
            return result;
          } else if (this.search_booking_status.value == 2) {
            var result = booking_cars.length > this.geBookingCarMax(booking_car_types);
            return result;
          }
        })
        ?.filter(({ booking_cars, booking_car_types }) => {
          return this.show_need_cars
            ? booking_cars?.length <
                booking_car_types
                  .map(({ quantity }) => (quantity ? quantity : 0))
                  .reduce((a, b) => a + b, 0)
            : true;
        })
        ?.filter(
          ({ booking_no, start_location_name, end_location_name, client_name }) => {
            return this.search_input
              ? start_location_name?.includes(this.search_input) ||
                  end_location_name?.includes(this.search_input) ||
                  booking_no.includes(this.search_input) ||
                  client_name.includes(this.search_input)
              : true;
          }
        )
        ?.filter(({ status, finished }) => {
          if (this.activeKey != null) {
            ///finished check
            if (this.activeKey == 100) {
              return finished;
            } else {
              return status == this.activeKey && !finished;
            }
          } else {
            return true;
          }
        })
        ?.filter((item) => {
          if (this.datetime) {
            var search_time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD");
            var search_time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD");
            return (
              (dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() &&
                dayjs(item.time_end).unix() <= dayjs(search_time_end).unix()) ||
              (dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() &&
                dayjs(item.time_end).format("YYYY-MM-DD") ==
                  dayjs(search_time_end).format("YYYY-MM-DD"))
            );
          }
          return true;
        })
        // .sort((a, b) => dayjs(b.time_start).unix() - dayjs(a.time_start).unix());
        .sort((a, b) => {
          const current_date = dayjs();
          const time_start = Math.abs(dayjs(a.time_start).diff(current_date));
          const time_end = Math.abs(dayjs(b.time_start).diff(current_date));
          return time_start - time_end;
        });
        

    },
    render_time(time) {
      return dayjs(time).format("DD/MM/YYYY HH:mm");
    },
    go_to_edit_quotation(id) {
      console.log('this.car_list[0].company_id', this.car_list[0].company_id)
      if (this.car_list[0].company_id == 39) {
        this.booking_sjinda = true;
        console.log('booking_sjinda', this.booking_sjinda)
        console.log('car company_id', this.car_list[0].company_id)
      } else {
        this.booking_sjinda = false;
      }
      this.$router.push(`/app/quotation/${id}?edit=true`);
    },
    init_toll_way() {
      this.show_create_toll_way = true;
      this.create_toll_way = {};
    },
    async init_bookings() {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      let datas = null
      let datas2 = null
      if(this.datetime){
        let time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD")
        let time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD")
        datas = await BookingService.get_bookings(
          this.pagination.onpage,
          this.pagination.perPage,
          1,
          {
            time_start: time_start,
            time_end: time_end,
          }
        );
      }else{
        datas = await BookingService.get_bookings(
          this.pagination.onpage,
          this.pagination.perPage,
          1
        );
      }
      // const combinedData = {
      //   total: datas.total + datas2.total,
      //   page: this.pagination.onpage,
      //   perPage: this.pagination.perPage,
      //   data: [...datas.data, ...datas2.data],
      // };
      
      const combinedData = {
      data: [...(datas ? datas.data : []), ...(datas2 ? datas2.data : [])],
      total: (datas ? datas.total : 0) + (datas2 ? datas2.total : 0),
      page: this.pagination.onpage,
      perPage: this.pagination.perPage,
    };

    // Calculate the actual total based on returned data
    const actualTotal = (this.pagination.onpage - 1) * this.pagination.perPage + combinedData.data.length;

    // Adjust the total count based on the actual data length
    if (combinedData.data.length < this.pagination.perPage) {
      combinedData.total = actualTotal;
    }

          
      this.pagination = {
        total: combinedData.total,
        onpage: combinedData.page,
        perPage: combinedData.perPage,
      };
      this.booking_list = combinedData.data;
      this.booking_list = this.booking_list?.sort(
        (a, b) => dayjs(a.time_start).unix() - dayjs(b.time_start).unix()
      );
      this.require_rollcall =
        JSON.parse(localStorage.getItem("companyData")).require_rollcall == 1;
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
  },
  watch: {
    require_rollcall(newVal) {
      if (newVal != null) {
        const { company_id } = JSON.parse(localStorage.getItem("user_profile"));
        SystemService.update_all(
          "Company",
          { data: { require_rollcall: newVal } },
          company_id
        ).then((res) => {
          const original = JSON.parse(localStorage.getItem("companyData"));
          original.require_rollcall = newVal;
          localStorage.setItem("companyData", JSON.stringify(original));
        });
      }
    },
  },
  async mounted() {
    this.init_bookings();
    this.pay_method_list = await SystemService.get_all("PayMethod");
    this.expense_list = await SystemService.get_all("Expense");
    this.gas_station_list = await SystemService.get_all("GasStation");
    this.car_list = await SystemService.get_all("Car");
    console.log("this.car_list", this.car_list);
  },
};
</script>